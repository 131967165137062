<template>
  <v-container
    id="create-financeur"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Créer un nouveau financeur
            </div>
          </template>

          <v-form
            method="post"
            ref="financeur"
            @submit.prevent="validate"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="Raison sociale *"
                    v-model="financeur.raisonSociale"
                    :rules="[v => !!v || 'Indiquez la raison sociale']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    outlined
                    label="Code facture *"
                    v-model="financeur.codeFacture"
                    hint="Deux lettres"
                    max="2"
                    :rules="[v => !!v || 'Indiquez le code facture']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                   <v-autocomplete
                    outlined
                    :items="['OPCO', 'CPF', 'PE', 'AUTRE']"
                    label="Type *"
                    v-model="financeur.type"
                    clearable
                    :rules="[v => !!v || 'Indiquez le type']"
                    required
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="E-mail"
                    type="email"
                    v-model="financeur.email"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="Téléphone"
                    type="tel"
                    v-model="financeur.telephone"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="SIREN"
                    v-model="financeur.siren"
                    type="number"
                    class="no-arrows"
                    @blur="updateSiret()"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    outlined
                    label="SIRET"
                    type="number"
                    class="no-arrows"
                    v-model="financeur.siret"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="12"
                >
                  <v-textarea
                    outlined
                    label="Adresse"
                    v-model="financeur.adresse.adressePostale"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Code postal"
                    v-model="financeur.adresse.codePostal"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Ville"
                    v-model="financeur.adresse.ville"
                    @change="formEdited = true"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                >
                  <v-text-field
                    outlined
                    label="Pays"
                    v-model="financeur.adresse.pays"
                    @change="formEdited = true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="gray"
                    class="mx-1"
                    @click="$router.back()"
                  >
                    Annuler
                  </v-btn>
                  <v-btn
                    v-if="!$route.params.id"
                    color="primary"
                    class="mx-1"
                    type="submit"
                  >
                    Créer le financeur
                  </v-btn>
                  <v-btn
                    v-if="$route.params.id"
                    color="primary"
                    class="mx-1"
                    type="submit"
                    @click="updateFinanceur()"
                  >
                    Mettre à jour le financeur
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        formEdited: false,
        financeur: {
          raisonSociale: '',
          codeFacture: '',
          type: '',
          siren: '',
          siret: '',
          adresse: {},
          contacts: [],
          nature: 'FINANCEUR',
        },
      }
    },

    beforeMount() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeDestroy() {
      window.addEventListener('beforeunload', this.preventNav);
    },

    beforeRouteLeave(to, from, next) {
      if (this.formEdited) {
        if (!window.confirm('Les modifications que vous avez apportées seront perdues, souhaitez-vous quitter la page ?')) {
          return;
        }
      }
      next();
    },

    created() {
      if (this.$route.params.id) {
        // edit mode
        this.axios.get('/financeurs/' + this.$route.params.id)
          .then((res) => {
            this.financeur = res.data;
            if (!this.financeur.adresse) {
              this.financeur.adresse = {};
            }
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la récupération du financeur', type: 'warn' });
          });
      }
    },

    methods: {
      validate () {
        this.formEdited = false;
        if (this.$refs.financeur.validate()) {
          if (this.$route.params.id) {
            // edit mode
            this.updateFinanceur();
          } else {
            // create mode
            this.createFinanceur();
          }
        } else {
          this.setSnack({ text: 'Veuillez renseigner tous les champs obligatoires', type: 'warn' });
        }
      },

      createFinanceur() {
        this.axios.post('/financeurs', this.financeur)
          .then(res => {
            this.setSnack({ text: 'Le financeur a bien été créé', type: 'success' });
            this.$router.push({ name: 'FinanceurDetail', params: { id: res.data.id } })
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la création du financeur', type: 'warn' });
          })
      },

      updateFinanceur() {
        this.axios.put('/financeurs/' + this.financeur.id, this.financeur)
          .then(res => {
            this.setSnack({ text: 'Le financeur a bien été mis à jour', type: 'success' });
            this.$router.push({ name: 'FinanceurDetail', params: { id: res.data.id } })
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la mise à jour du financeur', type: 'warn' });
          })
      },

      updateSiret() {
        if (!this.financeur.siret || this.financeur.siret === '') {
          this.financeur.siret = this.financeur.siren;
        }
      },

      preventNav(event) {
        if (this.formEdited) {
          event.preventDefault();
          event.returnValue = '';
          this.formEdited = false;
        }
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>
